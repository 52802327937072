import React from "react"
import { Link } from "gatsby"
import Image from './image2';



class List extends React.Component {

  render() {
    return (
      <div className="m_list">
        <div className="m_list_inner inner">
           {this.props.data.edges.map(edge => {
             const photo = edge.node;
               return (
                <React.Fragment key={photo.id}>
                 <section className="m_list_block">
                     <Link to={`/photo/${photo.slug}`}>
                     <div className="m_list_block_image">
                      <Image post={photo.list.url} alt={photo.title} size={["900","900","600"]} fade={true}/>
                     </div>
                     <div className="m_list_block_text">
                       <h2 className="m_list_block_ttl">{photo.title}</h2>
                       <p className="m_list_block_subttl">{photo.subttl}</p>
                     </div>
                     </Link>
                 </section>
               </React.Fragment>
               )
           })}
        </div>
      </div>
    )
  }
}


export default List
