import React from "react"
import Image from './image2';
import Slider from "react-slick";
import {formatToTimeZone} from 'date-fns-timezone';


class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {modalActive: false,src:""}
  }
  modal(e) {
    e.stopPropagation();
    this.setState({modalActive:true,src: e.target.src + "&w=1500"});
    var menu = document.querySelector(".m_head");
    menu.classList.add("on")
  }
  close(e){
    this.setState({modalActive: false,src:""});
    var menu = document.querySelector(".m_head");
    menu.classList.remove("on")
  }
  componentDidMount() {
    var self = this;
  }

  render() {
    var modalClass = this.state.modalActive  ? "m_news_modal on" : "m_news_modal";
    
    return (
      <div className="m_news">
        <div className={modalClass} onClick={this.close.bind(this)}>
          <div className="m_head_menu_btn on">
            <div className="m_head_menu_btn_inner" id="m_news_modal_inner">
              <span className="black"></span>
              <span className="black"></span>
            </div>
          </div>
          <div className="m_news_modal_inner">
            <img src={this.state.src} />
          </div>
        </div>
        <div className="m_news_scroll">
          <div className="m_news_inner inner_pc_tb">
            {(() => {
            var blockClass = this.props.data.length == 1 ? "m_news_block only" : "m_news_block";
            if (this.props.data.length > 0) {
              return (
                <div className="m_news_box ja">
                  {this.props.data.map(data => {
                    let news = data.node;
                    const FORMAT = 'YYYY.MM.DD';
                    const TIME_ZONE_TOKYO = 'Asia/Tokyo';
                    var date = formatToTimeZone(new Date(news.date), FORMAT, {timeZone: TIME_ZONE_TOKYO})
                    return (
                      <div key={news.text} className={blockClass}>
                        <div className="m_news_block_para"  dangerouslySetInnerHTML={{__html: news.text}} />
                        <div className="m_news_block_image" onClick={this.modal.bind(this)}>
                          {news.photo && (
                            <Image post={news.photo.url} alt="お知らせ" size={["700","700","700"]} fade={false}/>
                          )}
                        </div>
                        <p className="m_news_block_date">({date})</p>
                      </div>
                    )
                  })}
                </div>
              )
              }
              })()}
          </div>
        </div>
      </div>
    )
  }
}

export default News