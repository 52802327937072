import React from "react"
import classNames from 'classnames'
import HomeImage from "../images/cmn/home.svg"

class Top extends React.Component {

  constructor() {
    super();
    this.state = { percent:1,pic:"",isReady:false,height:"100vh"};
  }

  async componentDidMount() {
    const self = this;
    const ramdom = Math.floor(Math.random() * this.props.data.length)
    var winW = window.innerWidth;
    this.setState({pic:this.props.data[ramdom].image.url});
    this.setState({height:window.innerHeight + "px"});
    
    this.updateScroll = function(){
      var top = Math.max(window.pageYOffset,document.documentElement.scrollTop,document.body.scrollTop);
      var percent =  Math.max(1 - (top / window.innerHeight) - ((top / window.innerHeight)*0.8),0)
      self.setState({percent:percent});
    }
    this.setHeight = function(){
      if(winW !== window.innerWidth){
        self.setState({height:window.innerHeight + "px"});
        winW = window.innerWidth;
      }
    }
    window.addEventListener('scroll',this.updateScroll);
    window.addEventListener('resize',this.setHeight);
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    };
    const res = await loadImage(this.props.data[ramdom].image.url + "?auto=format,compress");
    this.setState({isReady:true});

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScroll); // Unmount時に外してあげる
    window.removeEventListener('resize', this.setHeight); // Unmount時に外してあげる
  }


  render() {
    const pic = this.state.pic;
    const height = {height:this.state.height};
    const comp = "?auto=format,compress";
    const opacity = {opacity:this.state.percent}
    var wrapperClass = classNames('p_index_top', {
        'on' : this.state.isReady
    })
    return (
     <div className={wrapperClass} style={height}>
       <div className="p_index_top_block" style={opacity}>
          <div className="p_index_top_block_image">
            <picture>
              <source media="(min-width:980px)" srcSet={`${pic}${comp}&w=450 1x,${pic}${comp}&w=900 2x`} />
              <source media="(min-width:768px)" srcSet={`${pic}${comp}&w=900 1x,${pic}${comp}&w=900 2x`} />
              <img src={`${pic}${comp}`} srcSet={`${pic}${comp}&w=600 1x,${pic}${comp}&w=600 2x`} alt="" />
            </picture>
          </div>
          <h1 className="p_index_top_block_ttl"><img src={HomeImage} alt="HARUHI FUJII" /></h1>
       </div>
     </div>
    )
  }
}

export default Top